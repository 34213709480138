<template>
	<div class="headBox white contentWidth">
<!--		<div class="contentWidth widths">-->
<!--			<div class="is-flex">-->
<!--				<img class="logo hoverHand" src="../../assets/logo.png" @click="goSellerCenter">-->
<!--				<div class="headMenuWidth">-->
<!--					<span class="hoverHand" @click="goSellerCenter">商家中心</span>-->
<!--					<el-dropdown>-->
<!--						<span class="el-dropdown-link" @click="pushTask">-->
<!--							发布任务<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--						</span>-->
<!--						<el-dropdown-menu slot="dropdown">-->
<!--							<el-dropdown-item>发布垫付任务</el-dropdown-item>-->
<!--							<el-dropdown-item>发布高权重任务</el-dropdown-item>-->
<!--							<el-dropdown-item>发布人气浏览任务</el-dropdown-item>-->
<!--							<el-dropdown-item>发布自媒体任务</el-dropdown-item>-->
<!--						</el-dropdown-menu>-->
<!--					</el-dropdown>-->
<!--					<el-dropdown>-->
<!--						<span class="el-dropdown-link">-->
<!--							任务管理<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--						</span>-->
<!--						<el-dropdown-menu slot="dropdown">-->
<!--							<el-dropdown-item>任务管理</el-dropdown-item>-->
<!--							<el-dropdown-item>订单管理</el-dropdown-item>-->
<!--							<el-dropdown-item>返款管理</el-dropdown-item>-->
<!--						</el-dropdown-menu>-->
<!--					</el-dropdown>-->
<!--					<el-dropdown>-->
<!--						<span class="el-dropdown-link">-->
<!--							评价管理<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--						</span>-->
<!--						<el-dropdown-menu slot="dropdown">-->
<!--							<el-dropdown-item>发布评价</el-dropdown-item>-->
<!--							<el-dropdown-item>发布追评</el-dropdown-item>-->
<!--						</el-dropdown-menu>-->
<!--					</el-dropdown>-->
<!--					<el-dropdown>-->
<!--						<span class="el-dropdown-link">-->
<!--							店铺管理<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--						</span>-->
<!--						<el-dropdown-menu slot="dropdown">-->
<!--							<el-dropdown-item>店铺管理</el-dropdown-item>-->
<!--							<el-dropdown-item>宝贝管理</el-dropdown-item>-->
<!--						</el-dropdown-menu>-->
<!--					</el-dropdown>-->
<!--					<el-dropdown>-->
<!--						<span class="el-dropdown-link">-->
<!--							资金管理<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--						</span>-->
<!--						<el-dropdown-menu slot="dropdown">-->
<!--							<el-dropdown-item>账户充值</el-dropdown-item>-->
<!--							<el-dropdown-item>资金明细</el-dropdown-item>-->
<!--						</el-dropdown-menu>-->
<!--					</el-dropdown>-->
<!--				</div>-->
<!--				<span></span>-->
<!--				<img src="../../assets/sysImage/videoImg.png" class="video hoverHand">-->
<!--			</div>-->
<!--		</div>-->
	</div>
</template>

<script>
	export default {
		name: 'PageHeader1',
		props: [],
		methods: {
			goSellerCenter() {
				if(this.$route.path !== '/pageIndex/sellerCenter'){
					this.$router.push('/pageIndex/sellerCenter')
				}
			},
			pushTask() {

			},
			getUserInfo() {

			}
		},
		mounted() {
			this.getUserInfo()
		}
	}
</script>

<style lang="less" scoped="scoped">
	// @primaryColor: yellow;
	.el-dropdown-link {
		cursor: pointer;
	}

	.el-dropdown-menu__item:focus,
	.el-dropdown-menu__item:not(.is-disabled):hover {
		background: #fff1ed !important;
		color: @primaryColorShen  !important;
	}

	// .headMenuWidth .el-dropdown-menu__item:hover{
	// 	color: yellow !important;
	// 	background-color: yellow !important;
	// 	// background-color: @primaryColorShen !important;
	// }

	.video {
		width: 54px;
		height: 40px;
		border-radius: 5rpx;
	}

	.headMenuWidth {
		width: 636px;
		display: flex;
		justify-content: space-between;
	}

	.widths {
		width: 100%;
	}

	.is-flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 60px;

	}

	.headBox {
		display: flex;
		align-items: center;

		.logo {
			width: 154px;
			height: 50px;
		}
	}
</style>
