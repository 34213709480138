<template>
	<div class="menu">
		<page-menu1></page-menu1>
	</div>
</template>

<script>
	import PageMenu1 from './headMenu_1.vue'
	export default {
		props: [],
		components:{
			PageMenu1,
		}
	}
</script>

<style lang="less" scoped>
	.menu{
		background-color: white;
	}
</style>
