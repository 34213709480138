<template>
	<div class="headsTop backgroundF5">
		<div class="layoutDefault">
			<div>
				<page-header></page-header>
				<page-head-menu></page-head-menu>
			</div>
			<div class="mainContent contentWidth backgroundF5">
				<div class="contents backF5">
					<div class="contentLeft white">
						<page-left-menu></page-left-menu>
					</div>
					<div class="view">
						<router-view></router-view>
					</div>
				</div>
				<page-footer></page-footer>
			</div>
		</div>
    <el-backtop></el-backtop>
	</div>
</template>

<script>
	import PageFooter from './footer/footerIndex.vue'
	import PageHeader from './header/hearderIndex.vue'
	import PageHeadMenu from './headMenu/headMenuIndex.vue'
	import pageLeftMenu from './leftMenu/leftMenuIndex.vue'
	import {USERINFO} from '../api/base'
	export default {
		props: [],
		components: {
			PageFooter,
			PageHeader,
			PageHeadMenu,
			pageLeftMenu,
		},
		methods: {
			getUserInfo(){
				this.$axios.get(USERINFO).then(res => {
					this.$store.commit('setting/setUserInfo', res.data)
				}).catch(err => {
					this.$mes({message: err.msg, type: 'error'})
				})
			},
		},
		mounted() {
			this.getUserInfo()
		}
	}
</script>

<style lang="less" scoped>
	.backF5 {
		background-color: #f5f5f5;
	}

	.layoutDefault {
		.mainContent {
			padding-top: 20px;
			margin: 0 auto;

			.contents {
				display: flex;
			}

			.view {
				width: 980px;
			}

			.contentLeft {
				max-width: 200px;
				margin-right: 20px;
				width: 100%;
			}
		}
	}
</style>
