<template>
  <div class="footer">
        <bei-an></bei-an>
  </div>
</template>

<script>
    import BeiAn from '../beiAn/beiAn'
export default {
  name: 'PageFooter',
    components:{
        BeiAn,

    },
  props: []
}
</script>

<style lang="less" scoped>
  
</style>
