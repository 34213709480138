<template>
	<div class="footer">
		<page-footer></page-footer>
	</div>
</template>

<script>
	import PageFooter from './PageFooter.vue'
	export default {
		props: [],
		components:{
			PageFooter,
		}
	}
</script>

<style lang="less" scoped>

</style>
