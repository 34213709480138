<template>
	<div>
		<div class="leftMenu" v-for="(item,index) in list " :key="index">
			<div class="menuTitle">{{item.title}}</div>
			<div class="menuItemBox" >
				<div class="menuItem hoverHand" v-for="(item1,index1) in item.children" :key="index1" @click="goPage(item1)">{{item1.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			list:{
				type:Array,
				default:()=>{
					return [
						{
							title:'商家',
							children:[
								// {
								// 	name:'任务管理',
								// 	path:'',
								// },
								{
									name: '商家中心',
									path: '/',
								},
								// {
								// 	name: '返款管理',
								// 	path: '',
								// },
							]
						},
					{
						title:'任务管理',
						children:[
              {
                name:'任务列表',
                path:'/pageIndex/taskList',
              },
							{
								name: '订单管理',
								path: '/pageIndex/orderInfo',
							},
							{
								name: '评价管理',
								path: '/pageIndex/evaluate',
							},
						]
					},
					// 	{
					// 	title: '快递管理',
					// 	children: [
					// 		{
					// 			name: '快递管理',
					// 			path: '',
					// 		},
					// 	]
					// },
					// 	{
					// 	title: '评价管理',
					// 	children: [
					// 		{
					// 			name: '发布评价',
					// 			path: '',
					// 		},
					// 		// {
					// 		// 	name:'发布追评',
					// 		// 	path:'',
					// 		// },
					// 		{
					// 			name: '评价管理',
					// 			path: '',
					// 		},
					// 		// {
					// 		// 	name:'追评管理',
					// 		// 	path:'',
					// 		// },
					// 	]
					// },
						// 	{
						// 	title:'运营管理',
						// 	children:[
						// 		{
						// 			name:'进度报表',
						// 			path:'',
						// 		},
						// 		{
						// 			name:'排名管理',
						// 			path:'',
						// 		},
						// 		{
						// 			name:'黑号查询',
						// 			path:'',
						// 		},
						// 		{
						// 			name:'猜你喜欢',
						// 			path:'',
						// 		},
						// 	]
						// },
						{
							title: '店铺与宝贝管理',
							children: [
								{
									name: '店铺管理',
									path: '/pageIndex/shopManagement',
								},
								{
									name: '优惠管理',
									path: '/pageIndex/coupon',
								},
								{
									name: '宝贝管理',
									path: '/pageIndex/goodsManagement',
								},
							]
						}, {
							title: '资金管理',
							children: [
								{
									name: '账户充值',
									path: '/pageIndex/recharge',
								},
								{
									name: '充值记录',
									path: '/pageIndex/rechargeList',
								},
								// {
								// 	name:'提现绑定',
								// 	path:'',
								// },
								// {
								// 	name:'账户提现',
								// 	path:'',
								// },
							]
						},
						{
							title: '个人资料',
							children: [
								// {
								// 	name: '个人信息',
								// 	path: '',
								// },
								{
									name: '密码修改',
									path: '/pageIndex/forgetPassword',
								},
							]
						},
            {
              title: '财务管理',
              children: [
                {
                  name: '资金明细',
                  path: '/pageIndex/bill',
                },
                {
                  name: '任务账单',
                  path: '/pageIndex/taskBill',
                },
              ]
            },
						// {
						// 	title: '推广管理',
						// 	children: [
						// 		{
						// 			name: '推广赚金',
						// 			path: '',
						// 		},
						// 		{
						// 			name: '下级明细',
						// 			path: '',
						// 		},
						// 		{
						// 			name: '抽成明细',
						// 			path: '',
						// 		},
						// 	]
						// },
						// {
						// 	title: '消息管理',
						// 	children: [
						// 		{
						// 			name: '站内信',
						// 			path: '',
						// 		},
						// 		{
						// 			name: '电商课堂',
						// 			path: '',
						// 		},
						// 	]
						// },
						// {
						// 	title: '申诉中心',
						// 	children: [
						// 		{
						// 			name: '订单申诉',
						// 			path: '',
						// 		},
						// 		{
						// 			name: '申诉记录',
						// 			path: '',
						// 		},
						// 	]
						// }
						]
				}
			},
		},
		methods: {
			goPage(item) {
				// if (item.path && (this.$route.path.indexOf(item.path) == -1)) {
				if (item.path && (this.$route.path != item.path)) {
					this.$router.push(item.path)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.leftMenu{
		padding: 0 15px;
		.menuItemBox{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 22px;
			.menuItem{
				max-width: 42.5%;
				width: 100%;
				margin-bottom: 20px;
			}
			.menuItem:hover{
				color: @primaryColorQian;
			}
		}
	}
	.menuTitle {
		margin-bottom: 26px;
		font-size: 14px;
		font-weight: 700;
		color: #333;
	}
</style>
