<template>
	<div >
		<layouts-main></layouts-main>
	</div>
</template>

<script>
	import layoutsMain from '../layouts/index.vue'
	export default {
		// name:'pageIndex',
		components:{
			layoutsMain,
		},
		data(){
			return{
				
			}
		},
		methods:{
			
		},
		mounted() {
			// console.log('jinlaileLE ')
		}
	}
</script>

<style lang="less">
	
	
</style>
