<template>
	<div class="menu white">
		<div class="pushBox">
			<div class="push hoverHand" @click="pushTask">
				发布任务
			</div>
		</div>
		<left-menu1></left-menu1>
	</div>
</template>


<script>
	import LeftMenu1 from './leftMenu_1.vue'
	export default {
		props: [],
		components:{
			LeftMenu1,
		},
		methods:{
			pushTask(){
				if (this.$route.path !== '/pageIndex/taskOne') {
					this.$router.push('/pageIndex/taskOne')
          this.$store.commit('setting/setOneTaskInfo', null)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.pushBox{
		padding:25px 0;
		.push{
			color: white;
			margin: 0 auto;
			width: 170px;
			height: 44px;
			line-height: 44px;
			text-align: center;
			border-radius: 22px;
			background: linear-gradient(-90deg ,#f28540,#ff714b 0,#ffa55d);
			border: 1px solid white;
		}
		.push:hover{
			color: #ff714b;
			background: white;
			border: 1px solid #ff714b;
		}
	}
	
</style>


