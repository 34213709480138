<template>
  <div style="padding: 25px 0 0;">
    <div style="text-align: center;padding-bottom: 10px;">
      © {{new Date().getFullYear()}}广州神笔网络科技有限公司 版权所有
    </div>
    <div style="text-align: center;padding-bottom: 20px">
      互联网ICP备案：<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备16104918号</a>
    </div>
  </div>

</template>

<script>
  export default {
    name:'beiAn',
    data() {
      return {}
    },

    methods: {}
  }
</script>

<style lang="less">
</style>
