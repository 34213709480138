<template>
	<div class="head">
		<page-head1></page-head1>
	</div>
</template>

<script>
	import PageHead1 from './header_1.vue'
	export default {
		props: [],
		components:{
			PageHead1,
		}
	}
</script>

<style lang="less" scoped>

</style>
