import { render, staticRenderFns } from "./footerIndex.vue?vue&type=template&id=68b99868&scoped=true&"
import script from "./footerIndex.vue?vue&type=script&lang=js&"
export * from "./footerIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b99868",
  null
  
)

export default component.exports