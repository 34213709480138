<template>
	<div class="backgroundF5 headBox">
		<div class="contentWidth heads backgroundF5">
			<div><span class="hoverHand orangeQian">{{userData.account}}</span>，<span class="hoverHand" @click="logout">退出</span></div>
			<div class="hoverHand">账户余额：<span class="orangeQian">{{userData.amount}} 元</span></div>
		</div>
	</div>
</template>

<script>
	import {LOGOUT} from "../../api/base";

	export default {
		name: 'PageHeader1',
		props: [],
		data(){
			return {
				userData:{}
			}
		},
		methods: {
			logout() {
				this.$confirm('确定退出登录吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.commit('setting/clearData', '')
					this.$axios.get(LOGOUT).then(res => {
						console.log(res)
						if (res.code == 200) {
							this.$mes({message: res.msg});
							this.$router.push({path:'/login'})
						}
					}).catch(err => {
						this.$mes({message: err.msg, type: 'error'})
					})
				}).catch(() => {

				});

			},
			getUserInfo() {
				
			}
		},
		mounted() {

			this.userData = this.$store.getters['setting/userInfo'];
		}
	}                                                    
</script>

<style  scoped="scoped">
	.heads{
		display: flex;
		justify-content: space-between;
		line-height: 35px;
		color: #666;
	}
	.white{
		background-color: white;
	}
	.headBox{
		width: 100%;
		
	}
</style>
